import { Context } from "@datadog/browser-core/cjs/tools/serialisation/context";
import { datadogRum } from "@datadog/browser-rum";

interface WorkoutViewContext {
    id: number;
    appId: number;
    appName: string;
    name: string;
    sport: string;
}

function addAction(name: string, context: Context) {
    datadogRum.addAction(name, context);
}
function addWorkoutViewContext(workout: WorkoutViewContext) {
    datadogRum.setViewContextProperty("workout", workout);
}

export default {
    addAction,
    addWorkoutViewContext,
};
