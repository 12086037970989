import * as React from "react";
import { useNavigate } from "react-router-dom";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import SwipeLeftIcon from "@mui/icons-material/SwipeLeft";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import userMonitor from "../userMonitor";

import { HomeScreenCategory } from "./HomeScreen";
import CreateWorkoutCard from "./Trainer/AppWorkouts/Shared/CreateWorkoutCard";
import { findApp } from "./Trainer/AppWorkouts/apps";
import { useSelectedSport } from "./common/context/sport";

interface HomeScreenRowProps {
    category: HomeScreenCategory;
}

export default function HomeScreenRow({
    category,
}: HomeScreenRowProps): JSX.Element {
    const { selected } = useSelectedSport();
    const navigate = useNavigate();
    const scrollTrackingRef = React.useRef<HTMLDivElement>(null);
    const observerRef = React.useRef<IntersectionObserver | null>(null);

    React.useEffect(() => {
        observerRef.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                observerRef.current?.disconnect();
                userMonitor.addAction("scroll-into-view", {
                    category: category.label,
                    sport: selected,
                });
            }
        }, {});

        return () => {
            observerRef.current?.disconnect();
        };
    }, [category.label, selected]);

    React.useEffect(() => {
        const handleScroll = () => {
            if (scrollTrackingRef.current) {
                userMonitor.addAction("horizontal-scroll", {
                    category: category.label,
                    sport: selected,
                });
                scrollTrackingRef.current.removeEventListener(
                    "scroll",
                    handleScroll,
                );
            }
        };

        if (scrollTrackingRef.current) {
            scrollTrackingRef.current.addEventListener("scroll", handleScroll);
            observerRef.current?.observe(scrollTrackingRef.current);
        }

        const lastRef = scrollTrackingRef.current;
        return () => {
            if (lastRef) {
                lastRef.removeEventListener("scroll", handleScroll);
            }
        };
    }, [category.label, selected]);

    return (
        <Stack key={category.name}>
            <Stack direction="row">
                {category.navUrl === undefined && (
                    <Typography
                        variant="button"
                        color="white"
                        sx={{ textTransform: "none" }}
                    >
                        {category.label}
                    </Typography>
                )}
                {category.navUrl && (
                    <Button
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={() => {
                            if (category.setFilter) {
                                category.setFilter();
                            }
                            navigate(
                                category.navUrl ??
                                    "/content/apps/workouts/search",
                            );
                        }}
                        color="primary"
                        sx={{
                            padding: 0,
                            color: "white",
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            minWidth="80%"
                            justifyContent="flex-start"
                        >
                            <Typography
                                variant="button"
                                color="white"
                                sx={{
                                    textTransform: "none",
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "block",
                                }}
                            >
                                {category.label}
                            </Typography>
                            <Typography
                                variant="button"
                                color="white"
                                sx={{
                                    textTransform: "none",
                                    display: "block",
                                }}
                            >
                                all
                            </Typography>
                        </Stack>
                    </Button>
                )}
            </Stack>
            <Stack
                ref={scrollTrackingRef}
                direction="row"
                spacing={1}
                sx={{
                    padding: "4px",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    scrollSnapType: "x mandatory",
                    "& > *": {
                        scrollSnapAlign: "center",
                        scrollSnapStop: "always",
                    },
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    maskImage:
                        "linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)",
                }}
            >
                {/*showSwipeLeft && (
                    <SwipeLeftIcon
                        sx={{
                            position: "relative",
                            right: 0,
                            top: "50%",
                            color: "white",
                            "@keyframes swipe": {
                                "0%": {
                                    transform: "rotate(45deg)",
                                },
                                "50%": {
                                    transform: "rotate(0deg)",
                                },
                                "100%": {
                                    transform: "rotate(-45deg)",
                                },
                            },
                            animation: "swipe 1000ms linear infinite",
                        }}
                    />
                )*/}
                {category.name === "my_workouts" && <CreateWorkoutCard />}
                {category.results.result.map((p) => {
                    const app = findApp(p.appId);
                    if (!app || app.CardComponent === null) {
                        return null;
                    }
                    return (
                        <app.CardComponent
                            key={p.id}
                            workout={p}
                            onClick={(id, navigateUrl) => navigate(navigateUrl)}
                        />
                    );
                })}
            </Stack>
        </Stack>
    );
}
