import * as React from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import {
    type KeenSliderOptions,
    type TrackDetails,
    useKeenSlider,
} from "keen-slider/react";

import type { Sport } from "../../../common/context/sport";
import { useCurrentUser } from "../../../hooks/currentUser";
import { useTrainerFeatures } from "../../../hooks/useTrainerFeatures";

interface SportRange {
    min: number;
    max: number;
}

const RANGE_BY_SPORT: Record<Sport, SportRange> = {
    PLATFORM_TENNIS: { min: -30, max: 30 },
    PADEL: { min: -30, max: 30 },
    TENNIS: { min: -30, max: 30 },
    PICKLEBALL: { min: -30, max: 30 },
};

const WHEEL_SIZE = 20;

const cacheKey = (workoutId: number) =>
    `volley:speedAdjustments:workoutId:${workoutId}`;

type Props = Readonly<{
    workoutId: number;
    sport: Sport;
    onChange: (value?: number) => void;
    initialValue?: number;
    disabled?: boolean;
}>;

const SpeedAdjustmentControl: React.FC<Props> = ({
    workoutId,
    sport,
    onChange,
    initialValue,
    disabled = false,
}) => {
    const theme = useTheme();
    const range = RANGE_BY_SPORT[sport];
    const slides = range.max - range.min + 1;

    const rangeValueFromIndex = React.useCallback(
        (i: number) => range.min + (((i % slides) + slides) % slides),
        [range.min, slides],
    );

    const indexFromRangeValue = React.useCallback(
        (rv: number) => (((rv - range.min) % slides) + slides) % slides,
        [range.min, slides],
    );

    const [sliderState, setSliderState] = React.useState<TrackDetails | null>(
        null,
    );
    const options = React.useRef<KeenSliderOptions>({
        slides: {
            number: slides,
            origin: "auto",
            perView: 1,
        },
        initial: initialValue
            ? indexFromRangeValue(initialValue)
            : Math.floor(slides / 2),
        loop: false,
        dragSpeed: 2,
        detailsChanged: (s) => {
            setSliderState(s.track.details);
        },
        rubberband: true,
        mode: "free-snap",
    });

    const [sliderRef, slider] = useKeenSlider(options.current);
    const [radius, setRadius] = React.useState(0);

    React.useEffect(() => {
        if (slider.current) setRadius(slider.current.size / 2);
    }, [slider]);

    React.useEffect(() => {
        if (slider.current) slider.current.options.disabled = disabled;
    }, [slider, disabled]);

    const sliderAbsValue = slider.current?.track.details.abs;

    React.useEffect(() => {
        if (!sliderAbsValue) return;
        const rangeValue = rangeValueFromIndex(sliderAbsValue);
        const key = cacheKey(workoutId);
        if (sliderAbsValue !== Math.floor(slides / 2)) {
            window.sessionStorage.setItem(key, rangeValue.toString());
        } else {
            window.sessionStorage.removeItem(key);
        }
        onChange(rangeValue || undefined);
    }, [sliderAbsValue, onChange, rangeValueFromIndex, workoutId, slides]);

    const slideValues = React.useMemo(() => {
        if (!sliderState) return [];
        const values = [];
        for (let i = 0; i < slides; i++) {
            const distance = sliderState.slides[i].distance;
            const rotate =
                Math.abs(distance) > WHEEL_SIZE / 2
                    ? 180
                    : distance * (360 / WHEEL_SIZE) * -1;
            const style = {
                color:
                    i === sliderState.abs &&
                    sliderState.abs !== Math.floor(slides / 2)
                        ? theme.palette.warning.main
                        : undefined,
                transform: `rotateY(${rotate}deg) translateZ(-${radius}px)`,
            };
            const formattedValue = rangeValueFromIndex(i);
            values.push({ style, value: formattedValue });
        }
        return values;
    }, [
        sliderState,
        radius,
        slides,
        rangeValueFromIndex,
        theme.palette.warning.main,
    ]);

    return (
        <Stack spacing={1}>
            <Typography id="speed-adjustment-label" variant="caption">
                Speed Adjustment
            </Typography>
            <div
                className="wheel__container"
                style={{
                    display: "block",
                    height: "40px",
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",
                    WebkitFontSmoothing: "antialiased",
                    MozOsxFontSmoothing: "antialiased",
                }}
                ref={sliderRef}
            >
                <div
                    className="wheel__shadow-start"
                    style={{
                        position: "absolute",
                        background:
                            "linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%)",
                        width: "calc(42% + 2px)",
                        transform: `translateZ(${radius}px)`,
                        top: 0,
                        left: 0,
                        height: "100%",
                        borderRight: `0.5px solid rgba(0, 0, 0, 0.3)`,
                        zIndex: 5,
                    }}
                />
                <div
                    className="wheel__inner"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        perspective: "1000px",
                        transformStyle: "preserve-3d",
                        height: "100%",
                        width: "100%",
                        perspectiveOrigin: "50% calc(50% - 100px)",
                        background: "white",
                    }}
                >
                    <div
                        className="wheel__slides"
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        {slideValues.map(({ style, value: v }) => (
                            <div
                                className="wheel__slide"
                                key={v}
                                onTouchStart={() => {}}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    backfaceVisibility: "hidden",
                                    fontSize: "20px",
                                    fontWeight: 400,
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    justifyContent: "center",
                                    userSelect: "none",
                                    WebkitUserSelect: "none",
                                    ...style,
                                }}
                            >
                                <span>
                                    {`${v > 0 ? "+" : ""}${v}${v === 0 ? "" : "%"}`}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className="wheel__shadow-end"
                    style={{
                        position: "absolute",
                        background:
                            "linear-gradient(to left, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%)",
                        width: "calc(42% + 2px)",
                        transform: `translateZ(${radius}px)`,
                        top: 0,
                        right: 0,
                        height: "100%",
                        borderLeft: `0.5px solid rgba(0, 0, 0, 0.3)`,
                        zIndex: 5,
                    }}
                />
            </div>
        </Stack>
    );
};

const SpeedAdjustment: React.FC<Props> = (props) => {
    const { features: userFeatures } = useCurrentUser();
    const { workoutId, onChange, initialValue } = props;
    const initialValuePercent = initialValue ? initialValue * 100 : undefined;
    const [open, setOpen] = React.useState(false);
    const trainerFeatures = useTrainerFeatures();

    const onSpeedChange = React.useCallback(
        (v?: number) => onChange(v ? v / 100 : undefined),
        [onChange],
    );

    React.useEffect(() => {
        const cachedValue = window.sessionStorage.getItem(cacheKey(workoutId));
        if (cachedValue) {
            const parsedValue = parseInt(cachedValue, 10);
            if (parsedValue && parsedValue !== initialValuePercent) {
                onSpeedChange(parsedValue);
                setOpen(true);
            }
        }
    }, [workoutId, onSpeedChange, initialValuePercent]);

    if (
        !trainerFeatures.includes("speedAdjustment") ||
        !userFeatures.includes("SPEED_ADJUSTMENT")
    )
        return null;

    if (open)
        return (
            <SpeedAdjustmentControl
                {...props}
                initialValue={initialValuePercent}
                onChange={onSpeedChange}
            />
        );

    return (
        <Button variant="outlined" onClick={() => setOpen(true)}>
            Speed Adjustment
        </Button>
    );
};

export default SpeedAdjustment;
