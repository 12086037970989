import { SingleShotParameters } from "@volley/shared/apps/single-shot-models";

import LocalWorkouts from "../../localWorkoutState";

export const DEFAULT_PARAMETERS: SingleShotParameters = {
    initialDelay: 1,
    intervalOverride: 2,
    numberOfBalls: 60,
    playMode: "standard",
};

export interface ParameterAction<K extends keyof SingleShotParameters> {
    type: K;
    value: SingleShotParameters[K];
}

export default function parametersReducer<K extends keyof SingleShotParameters>(
    state: SingleShotParameters,
    action: ParameterAction<K>,
): SingleShotParameters {
    const next = { ...state, [action.type]: action.value };
    LocalWorkouts.setFeed(next.intervalOverride, next.numberOfBalls);
    return next;
}
