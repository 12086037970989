import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { AuthState, useCurrentUser } from "../hooks/currentUser";

import Loading from "./Loading";

export default function RequireAuth({ children }: React.PropsWithChildren) {
    const location = useLocation();
    const from =
        location.pathname.indexOf("/login") > -1 ||
        location.pathname.indexOf("/account") > -1
            ? "/"
            : `${location.pathname}${location.search}`;
    const { currentUser, authState } = useCurrentUser();

    if (authState === AuthState.LOADING) {
        return <Loading />;
    }

    return currentUser ? children : <Navigate to="/login" state={{ from }} />;
}
