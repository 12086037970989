import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import userMonitor from "../../userMonitor";
import { HomeScreenCategory } from "../HomeScreen";
import { findApp } from "../Trainer/AppWorkouts/apps";
import { useSelectedSport } from "../common/context/sport";

type Direction = "PREV" | "NEXT";

interface FeaturedItemsState {
    pos: number;
    sliding: boolean;
    dir: Direction;
}

type FeaturedItemsAction =
    | { type: Direction; numItems: number }
    | { type: "stopSliding" };

const getOrder = (index: number, pos: number, numItems: number) => {
    return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

const getInitialState = (numItems: number): FeaturedItemsState => {
    const randIndex = Math.floor(Math.random() * numItems);
    return {
        pos: randIndex,
        sliding: false,
        dir: "NEXT",
    };
};

function reducer(
    state: FeaturedItemsState,
    action: FeaturedItemsAction,
): FeaturedItemsState {
    switch (action.type) {
        case "PREV":
            return {
                ...state,
                dir: "PREV",
                sliding: true,
                pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1,
            };
        case "NEXT":
            return {
                ...state,
                dir: "NEXT",
                sliding: true,
                pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1,
            };
        case "stopSliding":
            return { ...state, sliding: false };
        default:
            return state;
    }
}

interface FeaturedItemsProps {
    category: HomeScreenCategory;
}

export default function FeaturedItems({
    category,
}: FeaturedItemsProps): JSX.Element {
    const { selected } = useSelectedSport();
    const numItems = category.results.result.length;
    const [state, dispatch] = React.useReducer(
        reducer,
        getInitialState(numItems),
    );
    const navigate = useNavigate();

    const slide = (dir: Direction) => {
        userMonitor.addAction("horizontal-scroll", {
            category: category.label,
            sport: selected,
        });
        dispatch({ type: dir, numItems });
        setTimeout(() => {
            dispatch({ type: "stopSliding" });
        }, 50);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => (numItems > 1 ? slide("NEXT") : null),
        onSwipedRight: () => (numItems > 1 ? slide("PREV") : null),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    const transition = state.sliding ? "none" : "transform 800ms ease";
    const transform = React.useMemo(() => {
        let t = "translateX(0%)";
        if (state.sliding && state.dir === "NEXT") t = "translateX(100%)";
        if (state.sliding && state.dir === "PREV") t = "translateX(-100%)";
        return t;
    }, [state.sliding, state.dir]);

    return (
        <Stack {...handlers} spacing={1}>
            <Box
                sx={{
                    width: "100%",
                    overflow: "hidden",
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        transition,
                        transform,
                    }}
                >
                    {category.results.result.map((w, index) => {
                        const app = findApp(w.appId);
                        if (!app || app.CardComponent === null) {
                            return null;
                        }
                        return (
                            <app.HeroComponent
                                key={w.id}
                                workout={w}
                                onClick={(_id, navigateUrl) =>
                                    navigate(navigateUrl)
                                }
                                order={getOrder(index, state.pos, numItems)}
                            />
                        );
                    })}
                </Stack>
            </Box>
            {numItems > 1 && (
                <Stack direction="row" spacing={1} justifyContent="center">
                    {Array.from(Array(numItems)).map((_, index) => (
                        <CircleIcon
                            key={index}
                            sx={{
                                color:
                                    index === state.pos
                                        ? "primary.light"
                                        : "grey.500",
                                cursor: "pointer",
                                fontSize: "12px",
                            }}
                            onClick={() => {
                                if (index + 1 === state.pos) return;
                                dispatch({
                                    type: index > state.pos ? "NEXT" : "PREV",
                                    numItems,
                                });
                            }}
                        />
                    ))}
                </Stack>
            )}
        </Stack>
    );
}
