import * as React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import TuneIcon from "@mui/icons-material/Tune";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import {
    SingleShotConfig,
    SingleShotParameters,
} from "@volley/shared/apps/single-shot-models";

import { CoordSys } from "../../../../../util/position-types";
import { Sport, useSelectedSport } from "../../../../common/context/sport";
import SideSelector from "../../Shared/SideSelector";
import SpeedAdjustment from "../../Shared/SpeedAdjustment";
import ThrowCount from "../../Shared/ThrowCount";
import ThrowInterval from "../../Shared/ThrowInterval";
import { updateLocalParams } from "../../localWorkoutState";

import SingleShotDuplicateButton from "./SingleShotDuplicateButton";
import { ParameterAction } from "./parametersReducer";
import { WorkoutForm } from "./reducer";

interface Props {
    workout: WorkoutForm;
    parameters: SingleShotParameters;
    parametersDispatch: React.Dispatch<
        ParameterAction<keyof SingleShotParameters>
    >;
    onDelete: () => void;
    setMode: (mode: "play" | "edit") => void;
    saveWorkout: (
        asCopy: boolean,
        name?: string,
        description?: string,
    ) => Promise<void>;
    disabled?: boolean;
}

export default function PlayControls({
    workout,
    parameters,
    parametersDispatch,
    onDelete,
    setMode,
    saveWorkout,
    disabled = false,
}: Props): JSX.Element {
    const { selected: selectedSport } = useSelectedSport();

    const onParameterChange = React.useCallback(
        <K extends keyof SingleShotParameters>(action: ParameterAction<K>) => {
            updateLocalParams(
                { [action.type]: action.value },
                workout.appId,
                workout.id,
            );
            parametersDispatch(action);
        },
        [parametersDispatch, workout.appId, workout.id],
    );

    const playerPositionForSide = React.useMemo(() => {
        if (workout) {
            const { playerPosition } =
                workout.config as unknown as SingleShotConfig;
            return {
                x: playerPosition?.x ?? 0,
                y: playerPosition?.y ?? 0,
                sys: (selectedSport === "PLATFORM_TENNIS"
                    ? "court"
                    : "physics") as CoordSys,
            };
        }

        return {
            x: 0,
            y: 0,
            sys: "physics" as CoordSys,
        };
    }, [workout, selectedSport]);

    const onChangeSpeedAdjustment = React.useCallback(
        (value?: number) => {
            onParameterChange({ type: "speedAdjustment", value });
        },
        [onParameterChange],
    );

    return (
        <>
            <SideSelector
                disabled={disabled}
                playerPosition={playerPositionForSide}
                playMode={parameters.playMode}
                setPlayMode={(value) =>
                    onParameterChange({ type: "playMode", value })
                }
                workoutX={workout.positionX}
            />
            <SpeedAdjustment
                workoutId={workout.id}
                sport={workout.sport.name as Sport}
                onChange={onChangeSpeedAdjustment}
                initialValue={parameters.speedAdjustment}
                disabled={disabled}
            />
            <ThrowInterval
                disabled={disabled}
                selectedThrowInterval={parameters.intervalOverride}
                onUserThrowIntervalChanged={(value) =>
                    onParameterChange({ type: "intervalOverride", value })
                }
            />
            <ThrowCount
                disabled={disabled}
                selectedThrowCount={parameters.numberOfBalls}
                onUserThrowCountChanged={(value) =>
                    onParameterChange({ type: "numberOfBalls", value })
                }
            />
            <Stack direction="row" justifyContent="space-around">
                <Button
                    startIcon={<TuneIcon />}
                    onClick={() => setMode("edit")}
                >
                    Edit
                </Button>
                {workout.isOwner ? (
                    <SingleShotDuplicateButton
                        defaultName={workout.name}
                        disabled={!workout.isDirty}
                        onConfirm={async (name, description) => {
                            const asCopy =
                                name !== workout.name || workout.id === 0;
                            await saveWorkout(asCopy, name, description);
                        }}
                        title={workout.id === 0 ? "New" : "Save"}
                    />
                ) : (
                    <SingleShotDuplicateButton
                        defaultName={`Copy of ${workout.name}`}
                        onConfirm={async (name, description) => {
                            const asCopy = true;
                            await saveWorkout(asCopy, name, description);
                        }}
                        title={workout.isDirty ? "Save As" : "Duplicate"}
                    />
                )}
                {workout.isOwner && (
                    <Button startIcon={<DeleteIcon />} onClick={onDelete}>
                        Delete
                    </Button>
                )}
            </Stack>
        </>
    );
}
