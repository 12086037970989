import * as React from "react";

import { convert } from "@volley/physics";
import {
    AOI,
    AOIDetection,
    TrainerPosition,
} from "@volley/shared/dist/apps/app-common-models";

import { StyleOptions } from "../../../../Admin/Clips/canvasDrawing";
import {
    drawAOIForTennis,
    drawBallForTennis,
    drawTennisCourt,
} from "../../../../Admin/Clips/primitiveDrawing";

interface CourtProps {
    targetAOIs: AOI[];
    trainerPosition: TrainerPosition;
    detections: AOIDetection[];
}

function styleForDetection(serve: AOIDetection): StyleOptions {
    const base: StyleOptions = {
        strokeStyle: "white",
        lineWidth: 2,
        fillStyle: "white",
    };

    const color = serve.inAoi ? "yellow" : "red";

    return {
        ...base,
        strokeStyle: color,
        fillStyle: color,
    };
}

export default function Court({
    targetAOIs,
    trainerPosition,
    detections,
}: CourtProps) {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    convert.setPhysicsModel("physics-20B-base", "TENNIS");

    React.useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Clear the entire canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Redraw the court
        drawTennisCourt(ctx);

        // Draw all detections
        detections.forEach((result) => {
            drawBallForTennis(
                ctx,
                { ...result.position, z: 0 },
                styleForDetection(result),
            );
        });

        // Draw all AOIs
        targetAOIs.forEach((aoi, index) => {
            drawAOIForTennis(ctx, aoi, {
                strokeStyle: index === 0 ? "yellow" : "red",
                lineWidth: 2,
                fillStyle: "rgba(255, 255, 0, 0.10)",
            });
        });
    }, [detections, targetAOIs, trainerPosition]);

    return <canvas ref={canvasRef} width={300} height={400} />;
}
