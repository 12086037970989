import {
    CourtGeometry,
    CourtGeometryBySport,
    Sport,
} from "@volley/physics/dist/models";
import {
    Keypoint,
    BboxImage,
    PointQuad,
    People,
    Track,
    Point,
    CourtPosition,
    CourtAOI,
} from "@volley/shared/vision-models";

import {
    drawCircle,
    drawDiamond,
    drawLine,
    drawRectangle,
    StyleOptions,
} from "./canvasDrawing";

const DEFAULT_COLOR = "#FDDA0D";
const WHITE = "#FFFFFF";

// Court
const COURT_DARK_BLUE = "#224790";
const COURT_GREEN = "#518d47";
const COURT_LINE_COLOR = WHITE;
const COURT_OUT_OF_BOUNDS_COLOR = "#42695C";
const NET_COLOR = WHITE;
const COURT_LINE_WIDTH = 2;
const NET_LINE_WIDTH = 1;

// Person/Pose
const POSE_RADIUS = 3;
const POSE_CONNECTION_COLOR = "#FDDA0D";
const POSE_CONNECTION_WIDTH = 1;
const POSE_KEYPOINT_COLOR = "#00FF00";
const POSE_KEYPOINT_LINE_WIDTH = 1;
const PERSON_AOI_COLOR = "#00FFFF";
const PERSON_AOI_LINE_WIDTH = 1;
const PERSON_BOX_COLOR = "#00FF00";
const PERSON_BOX_LINE_WIDTH = 1;

// Ball tracking
const BALL_TRACK_COLOR = "#0000FF";
const BALL_TRACK_COLOR_LEADING = "#FF0000";
const BALL_TRACK_LINE_WIDTH = 1;
const BALL_TRACK_RADIUS = 2;
const BALL_BOUNCE_DETECT_COLOR = "#00FFFF";

const COURT_CORNERS_COLOR = "#0F1DF5";
const IMAGE_SPACE_COLOR = "#FF0000";
const POINT_QUAD_LINE_WIDTH = 1;

const drawPointQuad = (
    context: CanvasRenderingContext2D,
    pointQuad: PointQuad,
    scaleFactor: number,
    color = DEFAULT_COLOR,
) => {
    const style = { strokeStyle: color, lineWidth: POINT_QUAD_LINE_WIDTH };

    if (
        !pointQuad.point1 ||
        !pointQuad.point2 ||
        !pointQuad.point3 ||
        !pointQuad.point4
    ) {
        return;
    }

    drawLine(context, pointQuad.point1, pointQuad.point2, style, scaleFactor);
    drawLine(context, pointQuad.point2, pointQuad.point4, style, scaleFactor);
    drawLine(context, pointQuad.point4, pointQuad.point3, style, scaleFactor);
    drawLine(context, pointQuad.point3, pointQuad.point1, style, scaleFactor);
};

export const drawFilterImageSpace = (
    context: CanvasRenderingContext2D,
    filterImageSpace: PointQuad,
    scaleFactor: number,
) => {
    drawPointQuad(context, filterImageSpace, scaleFactor, IMAGE_SPACE_COLOR);
};

export const drawCourtCornersImageSpace = (
    context: CanvasRenderingContext2D,
    courtCornersImageSpace: PointQuad,
    scaleFactor: number,
) => {
    drawPointQuad(
        context,
        courtCornersImageSpace,
        scaleFactor,
        COURT_CORNERS_COLOR,
    );
};

/*
 * Draw a person AOI on the video canvas
 */
export const drawPersonAoi = (
    context: CanvasRenderingContext2D,
    personAoi: BboxImage,
    scaleFactor: number,
) => {
    const styleOptions = {
        strokeStyle: PERSON_AOI_COLOR,
        lineWidth: PERSON_AOI_LINE_WIDTH,
    };

    drawRectangle(context, personAoi, styleOptions, scaleFactor);
};

/*
 * Draw pose on the video canvas
 */
export const drawPerson = (
    context: CanvasRenderingContext2D,
    person: People,
    scaleFactor: number,
    drawBox: boolean,
    drawPose: boolean,
) => {
    const { bboxImage, pose } = person;

    if (drawBox) {
        const styleOptions = {
            strokeStyle: PERSON_BOX_COLOR,
            lineWidth: PERSON_BOX_LINE_WIDTH,
        };
        drawRectangle(context, bboxImage, styleOptions, scaleFactor);
    }

    if (drawPose && pose) {
        const poseStyle = {
            strokeStyle: POSE_KEYPOINT_COLOR,
            lineWidth: POSE_KEYPOINT_LINE_WIDTH,
            fillStyle: POSE_KEYPOINT_COLOR,
        };
        pose.keypoints?.forEach((keypoint) => {
            drawCircle(context, keypoint, POSE_RADIUS, poseStyle, scaleFactor);
        });

        if (pose.leftAnkle) {
            drawCircle(
                context,
                pose.leftAnkle,
                POSE_RADIUS,
                { ...poseStyle, strokeStyle: "red", fillStyle: "red" },
                scaleFactor,
            );
        }

        if (pose.rightAnkle) {
            drawCircle(
                context,
                pose.rightAnkle,
                POSE_RADIUS,
                { ...poseStyle, strokeStyle: "red", fillStyle: "red" },
                scaleFactor,
            );
        }

        const drawPoseLine = (a: Keypoint, b: Keypoint) => {
            // Do not draw lines for keypoints that are not visible
            // vision messages now always include default values so we have to filter out 0,0
            if (a.x === 0 && a.y === 0) {
                return;
            }

            if (b.x === 0 && b.y === 0) {
                return;
            }

            drawLine(
                context,
                a,
                b,
                {
                    strokeStyle: POSE_CONNECTION_COLOR,
                    lineWidth: POSE_CONNECTION_WIDTH,
                },
                scaleFactor,
            );
        };

        // connect keypoints based on the skeletal structure
        const connections: [Keypoint | undefined, Keypoint | undefined][] = [
            [pose.neck, pose.nose],
            [pose.nose, pose.leftEye],
            [pose.leftEye, pose.leftEar],
            [pose.nose, pose.rightEye],
            [pose.rightEye, pose.rightEar],
            [pose.leftShoulder, pose.rightShoulder],
            [pose.leftShoulder, pose.leftElbow],
            [pose.leftElbow, pose.leftWrist],
            [pose.rightShoulder, pose.rightElbow],
            [pose.rightElbow, pose.rightWrist],
            [pose.leftShoulder, pose.leftHip],
            [pose.leftHip, pose.rightHip],
            [pose.rightHip, pose.rightShoulder],
            [pose.rightHip, pose.rightKnee],
            [pose.rightKnee, pose.rightAnkle],
            [pose.leftHip, pose.leftKnee],
            [pose.leftKnee, pose.leftAnkle],
        ];

        connections.forEach(([start, end]) => {
            if (start && end) {
                drawPoseLine(start, end);
            }
        });
    }
};

const COLORS = [
    "red",
    "blue",
    "green",
    "yellow",
    "white",
    "purple",
    "orange",
    "pink",
    "aquamarine",
    "chartreuse",
    "coral",
    "darkorange",
];

function getColorById(id: number): string {
    const index = id % COLORS.length;
    return COLORS[index];
}

/*
 * Draw a ball track on the video canvas
 */
export const drawBallTrack = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
    scaleFactor: number,
) => {
    const { track, bounces } = ballTrack;
    const balls = ballTrack.track;
    const trackColor = getColorById(ballTrack.id);

    balls.forEach((ball, index) => {
        const { bboxImage: ballBox } = ball;

        if (index > 0) {
            const previousBall = track[index - 1];
            const trackLineStyle = {
                strokeStyle: trackColor,
                lineWidth: BALL_TRACK_LINE_WIDTH,
            };
            drawLine(
                context,
                previousBall.positionImage,
                ball.positionImage,
                trackLineStyle,
                scaleFactor,
            );
        }

        const ballStyle = {
            strokeStyle: index === 0 ? BALL_TRACK_COLOR_LEADING : trackColor,
        };
        drawCircle(
            context,
            ball.positionImage,
            ballBox.width / (index === 0 ? 2 : 4),
            ballStyle,
            scaleFactor,
        );
    });

    const bounceStyle = { strokeStyle: BALL_BOUNCE_DETECT_COLOR, lineWidth: 2 };
    bounces.forEach((bounce) => {
        drawDiamond(
            context,
            bounce.positionImage,
            20,
            bounceStyle,
            scaleFactor,
        );
    });

    // Print the Track ID at the position of the last ball or a default location
    const lastBall = balls[0];
    const textPosition = lastBall ? lastBall.positionImage : { x: 10, y: 30 }; // Default to top-left if no balls
    context.fillStyle = trackColor; // White text color for high contrast against dark backgrounds
    context.font = "16px Arial"; // Adjust font size/style as needed
    context.fillText(
        `${ballTrack.id}`,
        textPosition.x * scaleFactor,
        textPosition.y * scaleFactor,
    );
};

/**
 * Calculates the scale and center coordinates for canvas transformations.
 *
 */
function calculateCanvasTransformation(
    canvasWidth: number,
    canvasHeight: number,
    realWidth: number,
    realHeight: number,
) {
    const scaleFactorX = canvasWidth / realWidth;
    const scaleFactorY = canvasHeight / realHeight;
    const scale = Math.min(scaleFactorX, scaleFactorY);
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    return { scale, centerX, centerY };
}

/**
 * Converts a point from real-world coordinates to canvas coordinates.
 *
 * The real-world coordinates are in meters, with the origin at the center of the court.
 * The canvas coordinates are in pixels, with the origin at the top-left corner.
 */
export function convertPointToCanvasCoordinates(
    point: Point,
    canvasWidth: number,
    canvasHeight: number,
    realWidth: number,
    realHeight: number,
): Point {
    const { scale, centerX, centerY } = calculateCanvasTransformation(
        canvasWidth,
        canvasHeight,
        realWidth,
        realHeight,
    );
    const x = centerX + point.x * scale;
    const y = centerY - point.y * scale;
    return { x, y, z: point.z };
}

/**
 * Converts a bounding box from real-world coordinates to canvas coordinates.
 *
 * The real-world coordinates are in meters, with the origin at the center of the court.
 * The canvas coordinates are in pixels, with the origin at the top-left corner.
 */
export function convertBoundingBoxToCanvasCoords(
    bbox: BboxImage,
    canvasWidth: number,
    canvasHeight: number,
    realWidth: number,
    realHeight: number,
): BboxImage {
    const { scale, centerX, centerY } = calculateCanvasTransformation(
        canvasWidth,
        canvasHeight,
        realWidth,
        realHeight,
    );
    const xMinCanvas = centerX + bbox.xmin * scale;
    const yMinCanvas = centerY - bbox.ymin * scale;
    const widthCanvas = bbox.width * scale;
    const heightCanvas = bbox.height * scale;
    return {
        xmin: xMinCanvas,
        ymin: yMinCanvas - heightCanvas,
        width: widthCanvas,
        height: heightCanvas,
    };
}

function getCourtGeomtryForSport(sport: Sport): CourtGeometry {
    return CourtGeometryBySport[sport];
}

export const drawTennisCourt = (context: CanvasRenderingContext2D) => {
    return drawCourt(context, "TENNIS");
};

export const drawPlatformCourt = (context: CanvasRenderingContext2D) => {
    return drawCourt(context, "PLATFORM_TENNIS");
};

export const drawPickleballCourt = (context: CanvasRenderingContext2D) => {
    return drawCourt(context, "PICKLEBALL");
};

/**
 * Draw a court on the canvas
 */
export const drawCourt = (context: CanvasRenderingContext2D, sport: Sport) => {
    const courtGeometry = CourtGeometryBySport[sport];

    const convertPoint = (point: Point) =>
        convertPointToCanvasCoordinates(
            point,
            context.canvas.width,
            context.canvas.height,
            courtGeometry.PLATFORM_WIDTH,
            courtGeometry.PLATFORM_LENGTH,
        );

    const convertBbox = (bbox: BboxImage) =>
        convertBoundingBoxToCanvasCoords(
            bbox,
            context.canvas.width,
            context.canvas.height,
            courtGeometry.PLATFORM_WIDTH,
            courtGeometry.PLATFORM_LENGTH,
        );

    // platform
    const platformStyle = {
        strokeStyle: COURT_LINE_COLOR,
        lineWidth: COURT_LINE_WIDTH,
        fillStyle: COURT_OUT_OF_BOUNDS_COLOR,
    };
    drawRectangle(
        context,
        convertBbox({
            xmin: -courtGeometry.PLATFORM_WIDTH / 2,
            ymin: -courtGeometry.PLATFORM_LENGTH / 2,
            width: courtGeometry.PLATFORM_WIDTH,
            height: courtGeometry.PLATFORM_LENGTH,
        }),
        platformStyle,
    );

    // court
    const courtStyle = {
        strokeStyle: COURT_LINE_COLOR,
        lineWidth: COURT_LINE_WIDTH,
        fillStyle: COURT_DARK_BLUE,
    };
    drawRectangle(
        context,
        convertBbox({
            xmin: -courtGeometry.COURT_WIDTH / 2,
            ymin: -courtGeometry.COURT_LENGTH / 2,
            width: courtGeometry.COURT_WIDTH,
            height: courtGeometry.COURT_LENGTH,
        }),
        courtStyle,
    );

    if (sport === "PICKLEBALL") {
        // kitchen
        const kitchenStyle = {
            strokeStyle: COURT_LINE_COLOR,
            lineWidth: COURT_LINE_WIDTH,
            fillStyle: COURT_GREEN,
        };
        drawRectangle(
            context,
            convertBbox({
                xmin: -courtGeometry.COURT_WIDTH / 2,
                ymin: -courtGeometry.SERVICE_LENGTH / 2,
                width: courtGeometry.COURT_WIDTH,
                height: courtGeometry.SERVICE_LENGTH,
            }),
            kitchenStyle,
        );
    }

    // net
    const netStart = convertPoint({
        y: 0,
        x: -(courtGeometry.COURT_WIDTH / 2) - 0.1,
        z: 0,
    });
    const netEnd = convertPoint({
        y: 0,
        x: +(courtGeometry.COURT_WIDTH / 2) + 0.1,
        z: 0,
    });
    const netStyle = { strokeStyle: NET_COLOR, lineWidth: NET_LINE_WIDTH };
    drawLine(context, netStart, netEnd, netStyle);

    // centerlines
    const lineStyle = {
        strokeStyle: COURT_LINE_COLOR,
        lineWidth: COURT_LINE_WIDTH,
    };

    const singlesOffset =
        (courtGeometry.COURT_WIDTH - courtGeometry.SERVICE_WIDTH) / 2;

    const singlesLineStart = convertPoint({
        x: -courtGeometry.COURT_WIDTH / 2 + singlesOffset,
        y: courtGeometry.COURT_LENGTH / 2,
        z: 0,
    });
    const singlesLineEnd = convertPoint({
        x: -courtGeometry.COURT_WIDTH / 2 + singlesOffset,
        y: -courtGeometry.COURT_LENGTH / 2,
        z: 0,
    });
    drawLine(context, singlesLineStart, singlesLineEnd, lineStyle);

    const otherSinglesLineStart = convertPoint({
        x: courtGeometry.COURT_WIDTH / 2 - singlesOffset,
        y: courtGeometry.COURT_LENGTH / 2,
        z: 0,
    });
    const otherSingleLineEnd = convertPoint({
        x: courtGeometry.COURT_WIDTH / 2 - singlesOffset,
        y: -courtGeometry.COURT_LENGTH / 2,
        z: 0,
    });
    drawLine(context, otherSinglesLineStart, otherSingleLineEnd, lineStyle);

    const serviceLineStart = convertPoint({
        x: -courtGeometry.COURT_WIDTH / 2 + singlesOffset,
        y: courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    const serviceLineEnd = convertPoint({
        x: courtGeometry.COURT_WIDTH / 2 - singlesOffset,
        y: courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    drawLine(context, serviceLineStart, serviceLineEnd, lineStyle);

    const otherServiceLineStart = convertPoint({
        x: -courtGeometry.COURT_WIDTH / 2 + singlesOffset,
        y: -courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    const otherServiceLineEnd = convertPoint({
        x: courtGeometry.COURT_WIDTH / 2 - singlesOffset,
        y: -courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    drawLine(context, otherServiceLineStart, otherServiceLineEnd, lineStyle);

    const centerLineStart = convertPoint({
        x: 0,
        y: courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    const centerLineEnd = convertPoint({
        x: 0,
        y: -courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    drawLine(context, centerLineStart, centerLineEnd, lineStyle);

    // center tick mark
    if (sport === "PLATFORM_TENNIS") {
        // far court tick mark
        const centerTickStart = convertPoint({
            x: 0,
            y: courtGeometry.COURT_LENGTH / 2,
            z: 0,
        });
        const centerTickEnd = convertPoint({
            x: 0,
            y: courtGeometry.COURT_LENGTH / 2 - 0.1016, // 4 inches
            z: 0,
        });
        drawLine(context, centerTickStart, centerTickEnd, lineStyle);

        // near court tick mark
        const centerTickStartNear = convertPoint({
            x: 0,
            y: -courtGeometry.COURT_LENGTH / 2,
            z: 0,
        });
        const centerTickEndNear = convertPoint({
            x: 0,
            y: -courtGeometry.COURT_LENGTH / 2 + 0.1016, // 4 inches
            z: 0,
        });
        drawLine(context, centerTickStartNear, centerTickEndNear, lineStyle);
    }

    // these are for pickleball
    // const farCenterlineStart = convertPoint({ x: 0, y: courtGeometry.COURT_LENGTH / 2, z: 0 });
    // const farCenterlineEnd = convertPoint({ x: 0, y: courtGeometry.SERVICE_LENGTH / 2, z: 0 });
    // drawLine(context, farCenterlineStart, farCenterlineEnd, lineStyle);

    // const nearCenterlineStart = convertPoint({ x: 0, y: -courtGeometry.COURT_LENGTH / 2, z: 0 });
    // const nearCenterlineEnd = convertPoint({ x: 0, y: -courtGeometry.SERVICE_LENGTH / 2, z: 0 });
    // drawLine(context, nearCenterlineStart, nearCenterlineEnd, lineStyle);
};

export function convertCourtAoiToBboxImage(
    courtAoi: CourtAOI,
    context: CanvasRenderingContext2D,
    sport: Sport,
): BboxImage {
    const courtGeometry = CourtGeometryBySport[sport];
    const convertPoint = (point: Point) =>
        convertPointToCanvasCoordinates(
            point,
            context.canvas.width,
            context.canvas.height,
            courtGeometry.PLATFORM_WIDTH,
            courtGeometry.PLATFORM_LENGTH,
        );

    const topLeft = convertPoint({
        x: courtAoi.upperLeftX,
        y: courtAoi.upperLeftY,
        z: 0,
    });
    const bottomRight = convertPoint({
        x: courtAoi.lowerRightX,
        y: courtAoi.lowerRightY,
        z: 0,
    });

    return {
        xmin: topLeft.x,
        ymin: topLeft.y,
        width: bottomRight.x - topLeft.x,
        height: bottomRight.y - topLeft.y,
    };
}

export const drawRegions = (
    context: CanvasRenderingContext2D,
    regions: CourtAOI[],
    person: People,
    scaleFactor: number,
    sport: Sport = "PLATFORM_TENNIS",
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const personLocation = convertPointToCanvasCoordinates(
        person?.location || { x: 0, y: 0, z: 0 },
        context.canvas.width,
        context.canvas.height,
        courtGeometry.PLATFORM_WIDTH,
        courtGeometry.PLATFORM_LENGTH,
    );

    regions.forEach((region, index) => {
        // Convert the region AOI to a bounding box in canvas coordinates
        const regionBbox = convertCourtAoiToBboxImage(region, context, sport);

        // Check if the person's location is inside the region's bounding box
        const isInRegion =
            personLocation.x > regionBbox.xmin &&
            personLocation.x < regionBbox.xmin + regionBbox.width &&
            personLocation.y > regionBbox.ymin &&
            personLocation.y < regionBbox.ymin + regionBbox.height;

        // Highlight the region if the person is in it
        const regionStyle = {
            strokeStyle: isInRegion ? "red" : getColorById(index), // Highlight the region in red if the person is in it
            lineWidth: 2,
            fillStyle: isInRegion
                ? "rgba(255, 255, 0, 0.2)"
                : "rgba(0, 0, 0, 0)", // Optionally, add a fill if highlighted
        };

        // Draw the region
        drawRectangle(context, regionBbox, regionStyle, scaleFactor);
    });

    regions.forEach((region, index) => {
        const regionStyle = {
            strokeStyle: getColorById(index),
            lineWidth: 2,
        };
        drawRectangle(
            context,
            convertCourtAoiToBboxImage(region, context, sport),
            regionStyle,
            scaleFactor,
        );
    });
};

export const drawAOIForTennis = (
    context: CanvasRenderingContext2D,
    aoi: CourtAOI,
    rectangleStyle?: StyleOptions,
) => {
    return drawAOI(context, aoi, rectangleStyle, "TENNIS");
};

export const drawAOIForPickleball = (
    context: CanvasRenderingContext2D,
    aoi: CourtAOI,
    rectangleStyle?: StyleOptions,
) => {
    return drawAOI(context, aoi, rectangleStyle, "PICKLEBALL");
};

export const drawAOIForPlatformTennis = (
    context: CanvasRenderingContext2D,
    aoi: CourtAOI,
    rectangleStyle?: StyleOptions,
) => {
    return drawAOI(context, aoi, rectangleStyle, "PLATFORM_TENNIS");
};

export const drawAOI = (
    context: CanvasRenderingContext2D,
    aoi: CourtAOI,
    rectangleStyle?: StyleOptions,
    sport: Sport = "PLATFORM_TENNIS",
) => {
    const style = {
        strokeStyle: "red",
        lineWidth: 2,
        ...rectangleStyle,
    };

    drawRectangle(
        context,
        convertCourtAoiToBboxImage(aoi, context, sport),
        style,
        1,
    );
};

export const drawPersonCourtForTennis = (
    context: CanvasRenderingContext2D,
    person: People,
) => {
    return drawPersonCourt(context, person, "TENNIS");
};

export const drawPersonCourtForPickleball = (
    context: CanvasRenderingContext2D,
    person: People,
) => {
    return drawPersonCourt(context, person, "PICKLEBALL");
};

export const drawPersonCourtForPlatformTennis = (
    context: CanvasRenderingContext2D,
    person: People,
) => {
    return drawPersonCourt(context, person, "PLATFORM_TENNIS");
};

/**
 *  Draw a person on the court canvas
 */
export const drawPersonCourt = (
    context: CanvasRenderingContext2D,
    person: People,
    sport: Sport = "PLATFORM_TENNIS",
) => {
    const courtGeometry = getCourtGeomtryForSport(sport);
    const convertPoint = (point: Point) =>
        convertPointToCanvasCoordinates(
            point,
            context.canvas.width,
            context.canvas.height,
            courtGeometry.PLATFORM_WIDTH,
            courtGeometry.PLATFORM_LENGTH,
        );
    const { location } = person;

    const personStyle = {
        strokeStyle: POSE_CONNECTION_COLOR,
        lineWidth: 2,
    };

    drawCircle(context, convertPoint(location), 3, personStyle, 1);
};

export const drawBallForTennis = (
    context: CanvasRenderingContext2D,
    ball: Point,
    ballStyle?: StyleOptions,
) => {
    return drawBall(context, ball, ballStyle, "TENNIS");
};

export const drawBallForPickleball = (
    context: CanvasRenderingContext2D,
    ball: Point,
    ballStyle?: StyleOptions,
) => {
    return drawBall(context, ball, ballStyle, "PICKLEBALL");
};

export const drawBallForPlatformTennis = (
    context: CanvasRenderingContext2D,
    ball: Point,
    ballStyle?: StyleOptions,
) => {
    return drawBall(context, ball, ballStyle, "PLATFORM_TENNIS");
};

export const drawBall = (
    context: CanvasRenderingContext2D,
    ball: Point,
    ballStyle?: StyleOptions,
    sport: Sport = "PLATFORM_TENNIS",
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const style = ballStyle || {
        strokeStyle: "yellow",
        lineWidth: 2,
    };

    drawCircle(
        context,
        convertPointToCanvasCoordinates(
            ball,
            context.canvas.width,
            context.canvas.height,
            courtGeometry.PLATFORM_WIDTH,
            courtGeometry.PLATFORM_LENGTH,
        ),
        2,
        style,
        1,
    );
};

export const drawCameraCourtForTennis = (
    context: CanvasRenderingContext2D,
    camera: CourtPosition,
) => {
    return drawCameraCourt(context, camera, "TENNIS");
};

export const drawCameraCourtForPickleball = (
    context: CanvasRenderingContext2D,
    camera: CourtPosition,
) => {
    return drawCameraCourt(context, camera, "PICKLEBALL");
};

export const drawCameraCourtForPlatformTennis = (
    context: CanvasRenderingContext2D,
    camera: CourtPosition,
) => {
    return drawCameraCourt(context, camera, "PLATFORM_TENNIS");
};

export const drawCameraCourt = (
    context: CanvasRenderingContext2D,
    camera: CourtPosition,
    sport: Sport = "PLATFORM_TENNIS",
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const convertPoint = (point: Point) =>
        convertPointToCanvasCoordinates(
            point,
            context.canvas.width,
            context.canvas.height,
            courtGeometry.PLATFORM_WIDTH,
            courtGeometry.PLATFORM_LENGTH,
        );

    drawCircle(context, convertPoint(camera), 3, {
        strokeStyle: "red",
        lineWidth: 2,
    });
};

export const drawBallTrackForTennis = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
) => {
    return drawBallTrackCourt(context, ballTrack, "TENNIS");
};

export const drawBallTrackForPickleball = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
) => {
    return drawBallTrackCourt(context, ballTrack, "PICKLEBALL");
};

export const drawBallTrackForPlatformTennis = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
) => {
    return drawBallTrackCourt(context, ballTrack, "PLATFORM_TENNIS");
};

/*
 * Draw a ball track on the court canvas
 */
export const drawBallTrackCourt = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
    sport: Sport = "PLATFORM_TENNIS",
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const convertPoint = (point: Point) =>
        convertPointToCanvasCoordinates(
            point,
            context.canvas.width,
            context.canvas.height,
            courtGeometry.PLATFORM_WIDTH,
            courtGeometry.PLATFORM_LENGTH,
        );

    const { track, bounces } = ballTrack;

    track.forEach((ball, index) => {
        const ballLocation = convertPoint(ball.location);
        const ballStyle = {
            strokeStyle:
                index === 0 ? BALL_TRACK_COLOR_LEADING : BALL_TRACK_COLOR,
        };
        drawCircle(context, ballLocation, BALL_TRACK_RADIUS, ballStyle);
    });

    const bounceStyle = { strokeStyle: BALL_BOUNCE_DETECT_COLOR, lineWidth: 2 };
    bounces.forEach((bounce) => {
        const bounceLocation = convertPoint(bounce.position);
        drawDiamond(context, bounceLocation, 4, bounceStyle);
    });
};

// get court drawing functions for a particular sport
export const getCourtDrawingFunctionsForSport = (sport: Sport) => ({
    drawCourt: (context: CanvasRenderingContext2D) => drawCourt(context, sport),
    drawBallCourt: (context: CanvasRenderingContext2D, ball: Point) =>
        drawBall(context, ball, undefined, sport),
    drawPersonCourt: (context: CanvasRenderingContext2D, person: People) =>
        drawPersonCourt(context, person, sport),
    drawCameraCourt: (
        context: CanvasRenderingContext2D,
        camera: CourtPosition,
    ) => drawCameraCourt(context, camera, sport),
    drawAOICourt: (context: CanvasRenderingContext2D, aoi: CourtAOI) =>
        drawAOI(context, aoi, undefined, sport),
    drawBallTrackCourt: (context: CanvasRenderingContext2D, ballTrack: Track) =>
        drawBallTrackCourt(context, ballTrack, sport),
});

export const drawLegend = (
    context: CanvasRenderingContext2D,
    tracks: Track[],
    canvasWidth: number,
    scaleFactor: number,
) => {
    const startX = canvasWidth - 200 * scaleFactor; // Adjust as necessary for your canvas size
    const startY = 20 * scaleFactor;
    const lineHeight = 20 * scaleFactor; // Space between lines

    context.fillStyle = "rgba(255, 255, 255, 0.8)"; // Semi-transparent white for the background
    context.fillRect(
        startX,
        startY,
        180 * scaleFactor,
        tracks.length * lineHeight + 10 * scaleFactor,
    );

    context.font = `${16 * scaleFactor}px Arial`;
    context.fillStyle = "black";

    tracks.forEach((track, index) => {
        const textY = startY + (index + 1) * lineHeight;
        context.fillStyle = "black"; // Set the color to the track's color
        context.fillText(
            `${track.id}: len=${track.track.length}`,
            startX + 10 * scaleFactor,
            textY,
        );
    });
};

export const drawFrameId = (
    context: CanvasRenderingContext2D,
    frameId: number,
    timestamp: number,
    scaleFactor: number,
) => {
    const startX = 10 * scaleFactor;
    const startY = 20 * scaleFactor;

    context.fillStyle = "rgba(255, 255, 255, 0.8)"; // Semi-transparent white for the background
    context.fillRect(
        startX,
        startY - 16 * scaleFactor,
        180 * scaleFactor,
        45 * scaleFactor,
    );

    context.font = `${16 * scaleFactor}px Arial`;
    context.fillStyle = "black";
    context.fillText(`Frame ID: ${frameId}`, startX + 5, startY);
    context.fillText(`Timestamp: ${timestamp}`, startX + 5, startY + 10);
};
