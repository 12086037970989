import * as React from "react";

import type { TrainerWithRelations } from "@volley/data";

import logger from "../../log";
import { fetchApi } from "../../util";

import { useCurrentUser } from "./currentUser";
import { usePairingContext } from "./pairingStatus";

type WidgetConfig = Record<string, unknown>;

// https://developers.freshchat.com/web-sdk/v2/#messenger-api
interface Widget {
    close: () => void;
    init: (config?: WidgetConfig) => void;
    open: (params?: { name?: string; replyText?: string }) => void;
    isOpen: () => boolean;
    isInitialized: () => boolean;
    isLoaded: () => boolean;
    destroy: () => void;
    hide: () => void;
    show: () => void;
    setConfig: (config: WidgetConfig) => void;
    setExternalId: (externalId: string) => void;
    setTags: (tags: string[]) => void;
    setFaqTags: (faqTags: {
        tags: string[];
        filterType: "article" | "tag";
    }) => void;
    on: (
        event: string,
        handler: (arg1?: unknown, arg2?: unknown) => void,
    ) => void;

    user: {
        setFirstName: (firstName: string) => void;
        setLastName: (lastName: string) => void;
        setEmail: (email: string) => void;
        setProperties: (properties: Record<string, string>) => void;
    };

    conversation: {
        setBotVariables: (properties: Record<string, unknown>) => void;
        setConversationProperties: (
            properties: Record<string, unknown>,
        ) => void;
    };
}

interface WindowExt extends globalThis.Window {
    fcWidget?: Widget;
    fcWidgetMessengerConfig?: {
        config: {
            headerProperty: { hideChatButton: boolean };
            disableNotifications: boolean;
        };
    };
    fwcrm?: {
        on: (
            event: string,
            handler: (arg1?: unknown, arg2?: unknown) => void,
        ) => void;
    };
}

const WIDGET_DOM_ID = "freshchat-lib";

export default function useFreshchat() {
    const [widgetInstance, setWidgetInstance] = React.useState<Widget | null>(
        null,
    );
    const { trainerId } = usePairingContext();
    const { currentUser } = useCurrentUser();

    React.useEffect(() => {
        const win = window as unknown as WindowExt;
        if (win.fcWidget) {
            setWidgetInstance(win.fcWidget);
            return;
        }

        const existingScript = document.getElementById(WIDGET_DOM_ID);
        if (existingScript) return;

        win.fcWidgetMessengerConfig = {
            config: {
                headerProperty: { hideChatButton: true },
                disableNotifications: true,
            },
        };

        const script = document.createElement("script");
        script.src = "//fw-cdn.com/10823802/3602714.js";
        script.async = true;
        script.id = WIDGET_DOM_ID;
        script.setAttribute("chat", "true");

        script.onload = () => {
            logger.info("Freshworks external JS loaded");
            win.fwcrm?.on("widget:loaded", () => {
                if (win.fcWidget) {
                    logger.info("Freshchat global widget instance found");
                    setWidgetInstance(win.fcWidget);
                    if (currentUser?.username) {
                        logger.info("Setting Freshchat user variables");
                        win.fcWidget.setExternalId(currentUser.username);
                        win.fcWidget.user.setFirstName(currentUser.firstName);
                        win.fcWidget.user.setLastName(currentUser.lastName);
                        win.fcWidget.user.setEmail(currentUser.email);
                    }

                    if (trainerId) {
                        fetchApi<TrainerWithRelations>(
                            `/api/trainers/clientId_${trainerId}`,
                        )
                            .then((trainer) => {
                                if (!win.fcWidget) return;
                                logger.info(
                                    "Setting trainer context as Freshchat bot variables",
                                );
                                win.fcWidget.conversation.setBotVariables({
                                    trainer_id: trainer.clientId.toString(),
                                    trainer_url: `${window.location.origin}/admin/trainers/${trainer.id}`,
                                    location_name: trainer.location.name,
                                });
                            })
                            .catch((err: Error) => {
                                logger.error(
                                    "Failed to fetch trainer for Freshchat bot variables",
                                    undefined,
                                    err,
                                );
                            });
                    }
                }
            });
        };

        document.body.appendChild(script);
    }, [
        currentUser?.username,
        currentUser?.firstName,
        currentUser?.lastName,
        currentUser?.email,
        trainerId,
    ]);

    const close = React.useCallback(() => {
        if (!widgetInstance) return;

        widgetInstance?.close();
        widgetInstance?.hide();
    }, [widgetInstance]);

    const open = React.useCallback(
        (variables?: Record<string, unknown>) => {
            if (!widgetInstance) return;
            if (variables)
                widgetInstance.conversation.setBotVariables(variables);

            widgetInstance.open();
        },
        [widgetInstance],
    );

    return {
        close,
        open,
    };
}
