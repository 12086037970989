import * as React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import {
    SingleShotConfig,
    SingleShotParameters,
    SingleShotShot,
} from "@volley/shared/apps/single-shot-models";

import { updateLocalConfig } from "../../../localWorkoutState";
import { WorkoutAction, WorkoutForm } from "../reducer";

import PositionControls from "./PositionControls";
import ShotControls from "./ShotControls";
import WorkoutControls from "./WorkoutControls";

type TabValue = "shot" | "position" | "details";

interface Props {
    workout: WorkoutForm;
    dispatch: React.Dispatch<WorkoutAction>;
    parameters: SingleShotParameters;
    onCancel: () => void;
    onDone: () => void;
    onThrow: () => void;
    throwDisabled: boolean;
    editDisabled?: boolean;
    saveDisabled?: boolean;
}

export default function EditControls({
    workout,
    dispatch,
    parameters,
    onCancel,
    onDone,
    onThrow,
    throwDisabled,
    editDisabled = false,
    saveDisabled = false,
}: Props): JSX.Element {
    const [tab, setTab] = React.useState<TabValue>("shot");

    const updateShot = React.useCallback(
        <K extends keyof SingleShotShot>(
            property: K,
            value: SingleShotShot[K],
        ) => {
            if (!workout) return;
            updateLocalConfig({ [property]: value }, workout.appId, workout.id);
            const config = workout.config as unknown as SingleShotConfig;
            dispatch({
                type: "updateConfig",
                value: {
                    ...config,
                    shot: {
                        ...config.shot,
                        [property]: value,
                    },
                },
            });
        },
        [workout, dispatch],
    );

    return (
        <Stack>
            <Tabs
                value={tab}
                onChange={(_: unknown, value: TabValue) => setTab(value)}
                variant="fullWidth"
                sx={{
                    "& .MuiTab-root": {
                        fontSize: 10,
                        p: 0,
                        minHeight: 32,
                    },
                    minHeight: 32,
                    mb: 1,
                }}
            >
                <Tab label="Shot" value="shot" />
                <Tab label="Position" value="position" />
                {workout.isOwner && <Tab label="Details" value="details" />}
            </Tabs>
            {!tab && <Divider />}
            {tab === "shot" && (
                <ShotControls
                    playMode={parameters.playMode}
                    workout={workout}
                    updateShot={updateShot}
                    disabled={editDisabled}
                />
            )}
            {tab === "position" && (
                <PositionControls workout={workout} dispatch={dispatch} />
            )}
            {tab === "details" && (
                <WorkoutControls workout={workout} dispatch={dispatch} />
            )}
            <Stack direction="row" justifyContent="space-between" py={3} px={1}>
                <Button onClick={onCancel} startIcon={<CancelIcon />}>
                    Cancel
                </Button>
                <Button
                    disabled={saveDisabled}
                    onClick={onDone}
                    startIcon={<DoneIcon />}
                >
                    Done
                </Button>
                <Button
                    disabled={throwDisabled}
                    onClick={onThrow}
                    startIcon={<SportsTennisIcon />}
                >
                    Test
                </Button>
            </Stack>
        </Stack>
    );
}
