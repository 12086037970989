import * as React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import userMonitor from "../../userMonitor";

import PTICard from "./Card";

export default function PTIHomeRow(): JSX.Element {
    const cards = [
        {
            name: "Summary",
            urlSegment: "summary",
        },
        {
            name: "PTI Graph",
            urlSegment: "pti-graph",
        },
        {
            name: "Matches",
            urlSegment: "matches",
        },
        {
            name: "Partners",
            urlSegment: "partners",
        },
        {
            name: "Opponents",
            urlSegment: "opponents",
        },
        {
            name: "Locations",
            urlSegment: "locations",
        },
        {
            name: "Spread",
            urlSegment: "spread",
        },
    ];

    const scrollTrackingRef = React.useRef<HTMLDivElement>(null);
    const observerRef = React.useRef<IntersectionObserver | null>(null);

    React.useEffect(() => {
        observerRef.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                observerRef.current?.disconnect();
                userMonitor.addAction("scroll-into-view", { category: "PTI" });
            }
        }, {});

        return () => {
            observerRef.current?.disconnect();
        };
    }, []);

    React.useEffect(() => {
        const handleScroll = () => {
            if (scrollTrackingRef.current) {
                userMonitor.addAction("horizontal-scroll", { category: "PTI" });
                scrollTrackingRef.current.removeEventListener(
                    "scroll",
                    handleScroll,
                );
            }
        };

        if (scrollTrackingRef.current) {
            scrollTrackingRef.current.addEventListener("scroll", handleScroll);
            observerRef.current?.observe(scrollTrackingRef.current);
        }

        const lastRef = scrollTrackingRef.current;
        return () => {
            if (lastRef) {
                lastRef.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return (
        <Stack>
            <Typography
                variant="button"
                color="white"
                sx={{ textTransform: "none" }}
            >
                PTI
            </Typography>
            <Stack
                ref={scrollTrackingRef}
                direction="row"
                spacing={1}
                sx={{
                    padding: "4px",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    maskImage:
                        "linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)",
                }}
            >
                {cards.map((card) => (
                    <PTICard key={card.urlSegment} {...card} />
                ))}
            </Stack>
        </Stack>
    );
}
